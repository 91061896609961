import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MSAL_GUARD_CONFIG, MsalService } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AzureService } from './autenticacao/azure.service';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';
import { environment } from 'src/environments/environment';
import { ControleDeVersoesService } from './services/controle-de-versoes.service';
import { ActivatedRoute } from '@angular/router';
import { TokenServiceService } from './services/token-service.service';
import { CommomServicesService } from './services/commom-services.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'portal-front';
  isUserLoggedIn: boolean = false;
  cont: number = 0;
  groups: any;

  private readonly _destroy = new Subject<void>();

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    private azureService: AzureService,
    private controleVersao: ControleDeVersoesService,
    private userInfo: UserInfosService,
    private route: ActivatedRoute,
    private tokenS: TokenServiceService,
    private commomS: CommomServicesService
    //private msalService: MsalService

  ) {
    console.log((environment.homolog || environment.production || environment.localhost) && localStorage.getItem('sso') != 'accenture'? "sso nutrien": "sso accenture");
    //this.getAccounts();

  }
  ngOnDestroy(): void {
    this._destroy.next(undefined);
    this._destroy.complete()
  }
  // ngOnInit() {
  //   // this.controleVersaoS.atualizarPortal();

  //   // this.verificaTempoDeSessao()
  //   this.tokenS.getAccounts()
  //     .then(() => {
  //       this.tokenS.getSecrets();
  //     });

  //   this.msalBroadcastService.inProgress$.pipe
  //     (filter((interactionStatus: InteractionStatus) =>
  //       interactionStatus == InteractionStatus.None),
  //       takeUntil(this._destroy))
  //     .subscribe(x => {
  //       this.isUserLoggedIn = this.authService.instance.getAllAccounts().length > 0;
  //       this.azureService.isUserLoggedIn.next(this.isUserLoggedIn);
  //     });

  //   console.log(environment.versao + " - " + environment.versaoData + " - " + environment.versaoHora);
  //   if (!!environment.dev) {
  //      this.popUp();
  //   }

  //   this.setUserEmail()
  //   this.setUserName()

  //   setTimeout(() => {
  //     this.consultaVersao()
  //   }, 1000);

  //   this.groups = this.route.data.subscribe(
  //     (groups) => {
  //       this.groups = groups.groups;
  //     }
  //   )
  //   if(!(environment.production || environment.homolog)) {
  //      this.popUp();
  //   }
  // }
  ngOnInit() {
    this.tokenS.getAccounts()
      .then(() => {
        this.tokenS.getSecrets();
      });

    this.msalBroadcastService.inProgress$.pipe
      (filter((interactionStatus: InteractionStatus) =>
        interactionStatus == InteractionStatus.None),
        takeUntil(this._destroy))
      .subscribe(x => {
        this.isUserLoggedIn = this.authService.instance.getAllAccounts().length > 0;
        this.azureService.isUserLoggedIn.next(this.isUserLoggedIn);
      });


    this.groups = this.route.data.subscribe(
      (groups) => {
        this.groups = groups.groups;
      }
    )



  }

  acessaIpe() {
    this.controleVersao.podeAcessarIpe().then((res) => {
      // console.log(res);
    })
  }

  isLoggedIn(): boolean {
    return this.authService.instance.getActiveAccount() != null
  }

  logout(): void {
    this.authService.logoutRedirect()
  }



  // getInfos(): string | undefined {
  //   return this.authService.instance.getActiveAccount()?.name
  // }


  verificaPerfil(group: any) {
    var perfil = this.userInfo.getUserGroups().includes(group);
    return perfil;
  }

  getUserProfile() {
    this.userInfo.getUserGroupsFromAzure().subscribe((res: any) => {
      this.userInfo.setUserGroups(res);
    });
  }

  openForm() {
    let userEmail = this.userInfo.getUserEmail()
    let userName = this.userInfo.getName()

    userName = userName.replace(" ", "_").replace(",", "")

    const url = environment.sala_de_suporte + "?nome_do_solicitante=" + userName + "&e_mail_do_solicitante=" + userEmail
    this.commomS.openNewWindow(url);
  }


  // teste(cont: number) {
  //   console.log(this.cont)
  //   this.cont = this.cont + 1
  //   return this.cont
  // }


  // login(): void {

  //   if (this.msalGuardConfig.authRequest) {
  //     this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest)
  //   } else {
  //     this.authService.loginRedirect()
  //   }
  // }

  // acessaIpe() {
  //   this.controleVersao.podeAcessarIpe().then((res) => {
  //     console.log(res)
  //   })
  // }

  // consultaVersao() {
  //   if (!!environment.localhost) {
  //     return;
  //   }
  //   this.controleVersao.consultaVersao().then((res) => {

  //     let resposta = res as any;
  //     resposta = resposta.data.dados.status

  //     if (resposta == "não existe o email no pipe!" || resposta == "não ok") {
  //       this.atualizaVersao()
  //        this.popUp();

  //     }
  //     // console.log("versao ok")
  //   })
  // }

  // atualizaVersao() {
  //   this.controleVersao.atualizaVersao().then((res) => {
  //     //
  //   })
  // }

  // isLoggedIn(): boolean {
  //   return this.authService.instance.getActiveAccount() != null
  // }

  // logout(): void {
  //   this.authService.logoutRedirect()
  // }

  // verificaTempoDeSessao() {
  //   if (environment.localhost || environment.dev) {
  //     return;
  //   }
  //   const time = 14400000
  //   const myTme = setTimeout(() => {
  //     return Swal.fire({
  //       title: 'Ops, sua sessão expirou!',
  //       text: 'Por favor, saia e logue novamente.',
  //       icon: 'error',
  //       confirmButtonText: 'Sair',
  //       confirmButtonColor: '#ff6961',
  //       allowOutsideClick: false,
  //       allowEscapeKey: false
  //     })
  //       .then(() => {
  //         this.authService.logoutRedirect()
  //       }
  //       )
  //   }, time)
  // }



  // popUp() {
  //   Swal.fire({
  //     title: 'Comunicação de Melhoria',
  //     html: '<div style="text-align:justify; padding:20px">' +
  //     '<h4 style="text-align:center">Versão ' + environment.versao + ' - ' + environment.versaoData + '</h4>' +
  //     //   '<div>' +
  //     //   '<span style="color:#88D600">Atualizações automáticas a partir da versão 13.0.0</span><br>' +
  //     //   '<h6>Não se preocupe! Todas as atualizações deverão ocorrer em segundo plano de forma automática.</h6>' +
  //     //   '<span style="color:#88D600">Está com o portal desatualizado? Siga o passo a passo:</span></div>' +
  //     //'<img class="nbs_banner-img" src="../../../assets/img/melhoria.png" style= "width: 100%" >' +
  //      //'<h4 style="text-align:center">Outras atualizações</h4>' +
  //       'Fiscal</br></br>'+
  //        '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
  //        '<span style="color:#88D600">Inclusão de mensagem “Para as empresas Nutrien, Sementes, Agrichem e Fronteira favor acessar o link, (https://nbs.accenture.com/#/accouting/forms/baixa-imobilizado) preencher os campos / anexar documentos para operações de imobilizado.”, com link de redirecionamento. </span><br>' +
  //        '<b style="font-weight: bold;">MENU: </b>' +
  //        '<span>Fiscal / Cancelamento, Recusa, Emissão Manual - Notas Fiscais</span><br><br>' +
  //        'Contabilidade</br></br>' +
  //        '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
  //        '<span style="color:#88D600">Inclusão campo Tipo de transação com os itens: Venda; Venda via leilão; Venda entre empresas do mesmo grupo; Leilão; Transferência entre filiais. </span><br>' +
  //        '<b style="font-weight: bold;">MENU: </b>' +
  //        '<span>Contabilidade / Imobilizado.</span><br><br>' +
  //        '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
  //        '<span style="color:#88D600">Inclusão do campo PDF da nota fiscal de origem está em anexo? </span><br>' +
  //        '<b style="font-weight: bold;">MENU: </b>' +
  //        '<span>Contabilidade / Imobilizado.</span><br><br>' +
  //        '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
  //        '<span style="color:#88D600">Inclusão do campo Formulário de solicitação de emissão de nota fiscal está anexado e preenchido? </span><br>' +
  //        '<b style="font-weight: bold;">MENU: </b>' +
  //        '<span>Contabilidade / Imobilizado.</span><br><br>' +
  //        '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
  //        '<span style="color:#88D600">Inclusão de campo Formulário de autorização da contabilidade para emissão da nota fiscal está em anexo e assinado pela gestão? </span><br>' +
  //        '<b style="font-weight: bold;">MENU: </b>' +
  //        '<span>Contabilidade / Imobilizado.</span><br><br>' +
  //        '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
  //        '<span style="color:#88D600">Inclusão de campo Incluir anexos. </span><br>' +
  //        '<b style="font-weight: bold;">MENU: </b>' +
  //        '<span>Contabilidade / Imobilizado.</span><br><br>' +
  //        '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
  //        '<span style="color:#88D600">Inclusão de campo Número de nota fiscal. </span><br>' +
  //        '<b style="font-weight: bold;">MENU: </b>' +
  //        '<span>Contabilidade / Imobilizado.</span><br><br>' +
  //        '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
  //        '<span style="color:#88D600">Inclusão de campo Recebimento financeiro através de leilão? </span><br>' +
  //        '<b style="font-weight: bold;">MENU: </b>' +
  //        '<span>Contabilidade / Imobilizado.</span><br><br>' +
  //        '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
  //        '<span style="color:#88D600">Inclusão de campo Comprovante de pagamento. </span><br>' +
  //        '<b style="font-weight: bold;">MENU: </b>' +
  //        '<span>Contabilidade / Imobilizado.</span><br><br>' +
  //        '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
  //        '<span style="color:#88D600">Inclusão de campo Valor do Faturamento (Valor total da venda dos itens). </span><br>' +
  //        '<b style="font-weight: bold;">MENU: </b>' +
  //        '<span>Contabilidade / Imobilizado.</span><br><br>' +
  //       //  'Pagamentos</br></br>' +
  //       //  '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
  //       //  '<span style="color:#88D600">Inclusão da opção “Agrichem” no campo Empresa.</span><br>' +
  //       //  '<b style="font-weight: bold;">MENU: </b>' +
  //       //  '<span>Serviços Financeiros / Finanças / Cartão de Crédito Corporativo - Serviços</span><br><br>' +
  //       //  '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
  //       //  '<span style="color:#88D600">Para o Tipo de Nota de Crédito e Empresa Nutrien ou Agrichem, exibe o campo Conta Contábil.</span><br>' +
  //       //  '<b style="font-weight: bold;">MENU: </b>' +
  //       //  '<span>Serviços Financeiros > Finanças > Lançamento/Pagamento - Notas/Impostos e outras cobranças</span><br><br>' +
  //        '</br>'+


  //       '</div><br>' +
  //       // '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
  //       // '<span style="color:#88D600">: Inclusão da opção Recesso Estagiário no Menu Motivo da Exceção de Férias, no fluxo de Programar Férias < 40 Dias.</span><br>' +
  //       // '<b style="font-weight: bold;">MENU: </b>' +
  //       // '<span>myHR / Solicitações / Programar Férias < 40 Dias</span><br><br>' +
  //       '</div><br>' +
  //       '</div></div></div>',
  //     iconColor: '#88D600',
  //     confirmButtonColor: '#88D600',
  //     customClass: {
  //       container: "comunicadoMelhoria",
  //     }

  //   })

  // }

  // getInfos(): string | undefined {
  //   // console.log(this.authService.instance.getAllAccounts()[0])
  //   // console.log('name',this.authService.instance.getAllAccounts()[0]?.name)
  //   // console.log('username',this.authService.instance.getAllAccounts()[0]?.username)

  //   return this.authService.instance.getActiveAccount()?.name
  // }

  // setUserName(): any {
  //   this.userInfo.setName(this.authService.instance.getAllAccounts()[0]?.name)
  // }

  // setUserEmail(): any {
  //   this.userInfo.setUserEmail(this.authService.instance.getAllAccounts()[0]?.username)
  // }

  // getUserEmail() {
  //   return this.userInfo.getUserEmail()
  // }

  // verificaPerfil(group: any) {
  //   // console.log('perfil',this.userInfo.getUserGroups().includes(group))
  //   var perfil = this.userInfo.getUserGroups().includes(group)
  //   // console.log("group:", group, perfil)
  //   return perfil
  // }

  // getUserProfile() {
  //   this.userInfo.getUserGroupsFromAzure().subscribe((res: any) => {
  //     this.userInfo.setUserGroups(res)
  //   });
  // }

  // openForm() {
  //   let userEmail = this.userInfo.getUserEmail()
  //   let userName = this.userInfo.getName()

  //   userName = userName.replace(" ", "_").replace(",", "")

  //   const url = environment.sala_de_suporte + "?nome_do_solicitante=" + userName + "&e_mail_do_solicitante=" + userEmail
  //   this.commomS.openNewWindow(url);
  // }
  // async getAccounts(): Promise<any> {
  //   return await new Promise(resolve => {
  //     const checkValue = () => {
  //       if (!!this.msalService.instance.getAllAccounts()[0]?.name && !!this.msalService.instance.getAllAccounts()[0]?.username) {
  //         this.userInfo.setName(this.msalService.instance.getAllAccounts()[0]?.name);
  //         this.userInfo.setUserEmail(this.msalService.instance.getAllAccounts()[0]?.username);
  //         resolve(true);
  //       } else {
  //         setTimeout(checkValue, 50); // Espera 100ms
  //       }
  //     };
  //     checkValue();
  //   });
  // }



}
